/* Provide sufficient contrast against white background */
/*a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}*/

.paragraph {
    margin-top: 1em;
    margin-bottom: 1em; 
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pg-viewer-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pg-viewer-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.pdf-canvas {
    background-color: rgb(239,239,239);
}

.pdf-viewer .pdf-canvas canvas, .document-container {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 60%;
    border: 1px solid black;
    margin-top: 16px !important;
    margin-bottom: 32px !important;
}

.file-modal .MuiDialog-container .MuiPaper-root {
    background-color: rgb(239,239,239);
}

.photo-viewer-container, .photo-viewer-container img {
    height: initial !important;
    width: initial !important;
    max-height: 100%;
    max-width: 100%;
    margin-left: 16px;
    margin-right: 16px;
}

.photo-viewer-container img {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

/*Prevent ListSubheader being selectable*/
.MuiListSubheader-root {
    pointer-events: none;
}

.event-container img {
    max-width: 100%;
    height: auto !important;
}

.fc-daygrid-more-link {
    font-size: 2.5em;
    color: black;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
}

.fc-daygrid-day-bottom {
    text-align: center;
}

.fc .fc-popover {
    z-index: 1300;
    max-width: 1000px;
}

.fc-body .fc-row {
    min-height: 45px;
}


.fc-day-sun {
    background-color: #FBFCF3;
}

.fc-day-sat {
    background-color: #FBFCF3;
}



.rst__node {
    display: flex;
}

.rst__lineBlock {
    flex: 0 0 auto;
}

.rst__nodeContent {
    flex: 0 1 auto;
    left: auto !important;
    position: relative !important;
    top: auto !important;
    width: 100%;
}

.rst__highlightLineVertical::before {
    width: 0px;
}

.rst__highlightTopLeftCorner::before {
    border-top: none;
    border-left: none;
}

.rst__rtl.rst__highlightTopLeftCorner::before {
    border-right: none;
}

.rst__highlightBottomLeftCorner::before {
    border-bottom: none;
    border-left: none;
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
    border-right: none;
}

.rst__highlightBottomLeftCorner::after {
    border-left: none;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
    border-right: none;
}

.rst__rowSearchMatch {
    outline: solid 2px #7a5cf0;
}
